<template>
  <div class="container-prosker background_contrast mt-10">
      <v-row
        class="wide-banner"
        :style="{
          minHeight:'700px',
          backgroundImage: `url(${require('@/assets/images/page-img/Vector_12.png')})`,
          backgroundPosition: 'left bottom'
        }"
      >
        <v-col cols="12" md="6">
          <div class="flex-grown-1 h-100">
            <div class="p-5">
              <h1 class="font-weight-bold green-prosk secondary--text">{{ $t("faqs") }}</h1>
            </div>
          </div>
        </v-col>
        <v-col cols="12" md="6">
          <div class="p-5">
        <v-expansion-panels accordion flat class="mb-6 secondary-prosk" focusable v-model="activeTab">
          <v-expansion-panel v-for="(item, i) in faqs" :key="i" class="white border-radius-small mb-2">
            <v-expansion-panel-header
                class="font-weight-bold "
                :class="{'bg-green-prosk': i === activeTab,'title_highlight_color': i === activeTab, 'text-white': i === activeTab, 'secondary--text': i !== activeTab}"
            >
              <template v-slot:actions>
                <v-icon :color="i === activeTab ? 'white' : 'secondary'">
                  {{  i === activeTab ? 'mdi-minus' : 'mdi-plus' }}
                </v-icon>
              </template>
              {{ $t(item.question) }}
            </v-expansion-panel-header>
            <v-expansion-panel-content class="pt-5" :class="i === activeTab && 'title_highlight_color text-white'" :style="{borderBottomLeftRadius:'16px',borderBottomRightRadius:'16px'}">
              {{ $t(item.answer) }}
              <router-link v-show="item.link" :to="{ name: item.link }"> {{ $t("signUp") }}</router-link>
              <span v-show="item.answer2">{{$t(item.answer2)}}</span>
              <span v-show="item.answer3" class="font-weight-bold secondary-prosk"> {{$t(item.answer3)}} </span>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
         </v-col>
      </v-row>
  </div>
</template>

<script>

export default {
   name: 'FaqSection',
   data () {
       return {
            activeTab: null,
            faqs: [
        {
          question: 'whatIsPROSK',
          answer: 'whatIsPROSKAnswer'
        },
        {
          question: 'howDoIRegisterInPROSK',
          answer: 'createYourUsernamePasswordCompletingForm',
          link: 'auth1.sign-up1',
          answer2: 'thenCompleteYouProfile'
        },
        {
          question: 'isMyPROSKProfileUnique',
          answer: 'yourPROSKProfileIsUnique',
          answer2: 'whereSelectAllCategories'
        },
        {
          question: 'howCanIHaveGoodReputation',
          answer: 'TrustIsEssentialDependsOnQuality'
        },
        {
          question: 'howCanIMakeAttractiveProfile',
          answer: 'takingCareOfImage',
          answer2: 'takingCareOfImage2'
        }
        // {
        //   question: 'howDoUsersContactMe',
        //   answer: 'usersRegisteredInPROSKWillSendYouMessage'
        // },
        // {
        //   question: 'howDoesPROSKNotifyMe',
        //   answer: 'currentlyFromProfileWillHaveNotifications'
        // },
        // {
        //   question: 'canIChargeServices',
        //   answer: 'soonPROSKWillHaveServiceAcceptPayments'
        // },
        // {
        //   question: 'whatDoINeedToCollectServices',
        //   answer: 'whatDoINeedToCollectServicesAnswer'
        // },
        // {
        //   question: 'canISchedule',
        //   answer: 'soonPROSKWillHaveDigitalAgenda',
        //   answer2: 'soonPROSKWillHaveDigitalAgenda2'
        // },
        // {
        //   question: 'doesPROSKChargeCommission',
        //   answer: 'noThatDependsHowYouUsePlatform'
        // },
        // {
        //   question: 'doIHaveToPaySubscription',
        //   answer: 'itDependsOnYou',
        //   answer2: 'itDependsOnYou2',
        //   answer3: 'itDependsOnYou3'
        // },
        // { question: 'isThereTrialPeriod', answer: 'yesInAllCasesOffer30Days' },
        // {
        //   question: 'howDoIReceivePayment',
        //   answer: 'yourMercadoPagoAccount'
        // },
        // {
        //   question: 'howLongShouldIWaitCollectSales',
        //   answer: 'chargeDelay'
        // },
        // {
        //   question: 'doIHaveToIssueInvoices',
        //   answer: 'serviceYouProvideYouAreResponsible'
        // },
        // {
        //   question: 'ifIGetJobThroughAmICovered',
        //   answer: 'youAreResponsibleComplyingObligations'
        // },
        // {
        //   question: 'dataProtected',
        //   answer: 'dataProtectedAnswer',
        //   answer2: 'dataProtectedAnswer2'
        // }
      ]
       };
   }
};
</script>
<style scoped>
  .wide-banner {
    margin: 0 -36px !important;
    padding: 0 36px;
  }
</style>
