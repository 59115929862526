var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-main',{staticClass:"container-prosker"},[_c('v-row',{staticClass:"title_highlight_color border-radius-medium align-items-stretch mb-5 mt-5"},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('div',{style:({
  backgroundImage: `url(${require('@/assets/images/page-img/Vector_9.png')})`,
  backgroundPosition: 'left 0px top 0px'
})},[_c('img',{staticClass:"img-fluid",attrs:{"src":_vm.i18nImage('home_banner_profiles.png')}})])]),_c('v-col',{style:({
  backgroundImage: `url(${require('@/assets/images/page-img/Vector_7.png')})`,
  backgroundPosition: 'left 0px bottom'
}),attrs:{"cols":"12","sm":"6"}},[_c('div',{staticClass:"d-flex flex-column justify-content-center h-100"},[_c('h1',{staticClass:"text-h3 secondary--text font-weight-bold text-center text-md-left text-white"},[_vm._v(_vm._s(_vm.$t('proskerBannerTitle')))]),_c('p',{staticClass:"font-size-16 text-center text-md-left mt-2"},[_vm._v(_vm._s(_vm.$t('freeTrial30')))]),_c('div',{staticClass:"d-flex justify-content-center justify-content-md-start"},[_c('general-button',{attrs:{"action":_vm.goToSignUp,"loading":false,"message":_vm.$t('startNow'),"size":"medium","cssClasses":"mt-4"}})],1)])])],1),_c('v-row',[_c('v-col',{staticClass:"d-flex flex-column p-5 p-md-4",attrs:{"col":"12","md":"6"}},[_c('h1',{staticClass:"text-h3 secondary--text font-weight-bold mb-5"},[_vm._v(_vm._s(_vm.$t('subscriptionText1')))]),_c('topic',{attrs:{"title":_vm.$t('boostBusiness'),"description":_vm.$t('increaseVisibility'),"imageUrl":require('@/assets/images/page-img/startup--shop-rocket-launch-startup.svg')}}),_c('topic',{attrs:{"title":_vm.$t('optimize'),"description":_vm.$t('forgetPendencies'),"imageUrl":require('@/assets/images/page-img/optimiza-trabajo.svg')}}),_c('topic',{attrs:{"title":_vm.$t('enjoyTheTools'),"description":_vm.$t('automateProcess'),"imageUrl":require('@/assets/images/page-img/herramientas.svg')}}),_c('div',{staticClass:"text-center text-md-left"},[_c('general-button',{attrs:{"action":_vm.goToSignUp,"loading":false,"message":_vm.$t('startNow'),"size":"medium","cssClasses":"mt-4"}})],1)],1),_c('v-col',{staticClass:"p-md-4",attrs:{"cols":"12","sm":"12","md":"6"}},[_c('div',{staticClass:"d-flex justify-content-center"},[_c('img',{staticClass:"img-fluid",attrs:{"src":_vm.i18nImage('subscription_topics_bannes.png')}})])])],1),_c('v-row',{staticClass:"mt-5",style:({
  backgroundImage: `url(${require('@/assets/images/page-img/Vector_8.png')})`,
  backgroundPosition: 'left 0px bottom'
})},[_c('v-col',{staticClass:"p-md-4",attrs:{"cols":"12","md":"6"}},[_c('h1',{staticClass:"text-h3 secondary--text font-weight-bold text-center text-md-left"},[_vm._v(_vm._s(_vm.$t('digitalOffice')))])]),_c('v-col',{staticClass:"mb-5",attrs:{"cols":"12","md":"6"}},[_c('p',{staticClass:"text-center text-md-left font-size-16 mt-4",domProps:{"innerHTML":_vm._s(_vm.$t('dedicate_love'))}}),_c('div',{staticClass:"d-none d-md-block"},[_c('general-button',{attrs:{"action":_vm.goToSignUp,"loading":false,"message":_vm.$t('wantToSignUp'),"size":"medium","cssClasses":"mt-4","secondary":""}})],1)]),_vm._l((_vm.cardsAdvantages),function(item,n){return _c('v-col',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$vuetify.breakpoint.mobile),expression:"!$vuetify.breakpoint.mobile"}],key:n,attrs:{"col":"12","md":"4"}},[_c('advantages-card',{attrs:{"description":item.text,"title":item.title,"imageUrl":require(`@/assets/images/page-img/${item.icon}`),"maxWith":"100%"}})],1)}),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('hooper',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.mobile),expression:"$vuetify.breakpoint.mobile"}],staticClass:"h-100",attrs:{"centerMode":true,"settings":_vm.hooperSettings}},[_vm._l((_vm.cardsAdvantages),function(item,n){return _c('slide',{key:n},[_c('advantages-card',{attrs:{"description":item.text,"title":item.title,"cssClasses":"ma-2","imageUrl":require(`@/assets/images/page-img/${item.icon}`)}})],1)}),_c('hooper-pagination',{attrs:{"slot":"hooper-addons"},slot:"hooper-addons"})],2)],1)],2),_c('v-row',{staticClass:"background_contrast border-radius-medium p-4 mt-16",style:({
  backgroundImage: `url(${require('@/assets/images/page-img/Vector_11.png')})`,
  backgroundPosition: 'left 0px bottom'
})},[_c('v-col',{attrs:{"col":"12","sm":"12","md":"6"}},[_c('h2',{staticClass:"secondary-prosk font-weight-bold text-left mt-16 w-100"},[_vm._v(_vm._s(_vm.$t('reachMoreBuyers')))]),_c('p',{staticClass:"text-left mb-8 secondary--text w-50"},[_vm._v(_vm._s(_vm.$t('potentiate')))])]),_c('v-col',{attrs:{"col":"12","sm":"12","md":"6"}},[_c('v-card',{staticClass:"border-radius-medium p-5 d-flex flex-column justify-content-between",attrs:{"min-height":"500"}},[_c('topic',{attrs:{"title":_vm.$t('boostBusiness'),"description":_vm.$t('increaseVisibility'),"imageUrl":require('@/assets/images/page-img/startup--shop-rocket-launch-startup.svg')}}),_c('topic',{attrs:{"title":_vm.$t('optimize'),"description":_vm.$t('forgetPendencies'),"imageUrl":require('@/assets/images/page-img/optimiza-trabajo.svg')}}),_c('topic',{attrs:{"title":_vm.$t('enjoyTheTools'),"description":_vm.$t('automateProcess'),"imageUrl":require('@/assets/images/page-img/herramientas.svg')}})],1)],1)],1),_c('v-row',[_c('v-row',[_c('v-col',{attrs:{"col":"12"}},[_c('h2',{staticClass:"secondary-prosk font-weight-bold text-center mt-16"},[_vm._v(_vm._s(_vm.$t('nextLevel')))]),_c('p',{staticClass:"text-center main_text_color--text mb-8"},[_vm._v(" "+_vm._s(_vm.$t('subscribeAndGrowYourBusiness'))+" ")])])],1),_c('v-container',[_c('div',{class:_vm.$vuetify.breakpoint.mobile ? 'mx-8' : 'mx-16'},[(_vm.loading)?_c('v-row',{staticClass:"mx-16"},_vm._l((2),function(index){return _c('v-col',{key:index,attrs:{"cols":"6"}},[_c('v-skeleton-loader',_vm._b({attrs:{"type":"article, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line,\n                         list-item-two-line, actions"}},'v-skeleton-loader',_vm.skeletonAttrs,false))],1)}),1):_c('v-row',_vm._l((this.subscriptionPlans),function(item,i){return _c('v-col',{key:i,staticClass:"pa- d-flex align-stretch",attrs:{"cols":"12","md":"6"}},[_c('v-card',{class:{
                      'title_highlight_color pt-3 pb-5 px-5 border-radius-medium': item.popular,
                      'pt-3 pb-5 px-5 border-radius-medium ml-0 ml-md-5 mt-5 mt-md-0': !item.popular,
                    },attrs:{"elevation":item.popular ? 0 : 2}},[_c('v-card-text',[_c('h4',{staticClass:"text-center font-weight-bold text-h4",class:{
                              'text-white': item.popular,
                              'main_text_color--text': !item.popular
                            }},[_vm._v(_vm._s(item.name))]),(item.name === 'Premium')?_c('h5',{staticClass:"text-overline text-center text-white"},[_vm._v("( "+_vm._s(_vm.$t('freeTrial'))+" ) ")]):_vm._e(),(item.name !== 'Premium')?_c('h5',{staticClass:"text-overline text-center"},[_vm._v(_vm._s(_vm.$t('simple_start')))]):_vm._e(),_c('div',{staticClass:"button text-center w-100"},[_c('div',{staticClass:"d-flex align-center w-100 justify-content-center mb-4"},[_c('span',{staticClass:"display-1 font-weight-bold",class:{
                              'text-white': item.popular,
                              'main_text_color--text': !item.popular
                            }},[_c('money-format',{attrs:{"value":item.cost,"locale":_vm.$i18n.locale,"currency-code":_vm.currencyCode,"supplemental-precision":0,"hide-subunits":true}})],1),_c('span',{staticClass:"font-weight-bold",class:{
                              'text-white': item.popular,
                              'main_text_color--text': !item.popular
                            }},[_vm._v("/"+_vm._s(_vm.$t("month")))])]),_c('div',{staticClass:"text-center"},[(_vm.$options.SUBSCRIPTIONS_ENABLED)?_c('general-button',{attrs:{"action":_vm.sendEmailOrLogin,"loading":false,"message":_vm.$t('startCTA'),"size":"medium","cssClasses":"mb-2 w-100 secondary--text","white":!!item.popular}}):_vm._e()],1)]),_c('v-divider'),_c('h4',{staticClass:"text-left font-weight-bold",class:{
                              'text-white': item.popular,
                              'main_text_color--text': !item.popular
                            }},[_vm._v(_vm._s(_vm.$t('includes')))]),(item.popular)?_c('v-col',{attrs:{"offset":"1","cols":"10"}},_vm._l((_vm.itemsPremium),function(item,i){return _c('v-list-item',{key:i},[_c('img',{attrs:{"src":require("@/assets/images/page-img/Check-green-purple.svg")}}),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"mt-1 ml-2 space-wrap text-white"},[_vm._v(" "+_vm._s(_vm.$t(item.text))+" ")])],1)],1)}),1):_c('v-col',{staticClass:"align-content-center",attrs:{"offset":"1","cols":"10"}},_vm._l((_vm.items),function(item,i){return _c('v-list-item',{key:i},[_c('img',{attrs:{"src":require("@/assets/images/page-img/Check-green-black.svg")}}),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"mt-1 ml-2 space-wrap main_text_color--text"},[_vm._v(" "+_vm._s(_vm.$t(item.text))+" ")])],1)],1)}),1)],1)],1)],1)}),1)],1)])],1),_c('faq-section')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }